import { ChakraProps, Flex } from '@chakra-ui/react'
import AppProgressBar from '../AppProgressBar'

type PageProps = ChakraProps & {
  children: React.ReactNode
  minimal?: boolean
}

// Currently have a single layout for all pages
const Layout = ({ children, ...props }: PageProps) => (
  <Flex overflow='auto' w='full' h='full' justify='center'>
    <Flex
      direction='column'
      align='center'
      mx='auto'
      position='absolute'
      top={{ base: 0, md: 4 }}
      bottom={{ base: 0, md: 4 }}
      rounded={{ base: 'none', md: 'md' }}
      overflow='hidden'
      as='main'
      w={{ base: '100%', md: '560px' }}
      m='0 auto'
      h='auto'
      {...props}
    >
      <AppProgressBar />
      {children}
    </Flex>
  </Flex>
)

export default Layout
