import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Divider,
  ModalCloseButton,
  Input,
  Select,
  VStack,
  Flex,
  Textarea,
  Button,
  Checkbox,
  Box,
} from '@chakra-ui/react'
import { kGenders } from 'constants/profile'
import Analytics from 'lib/analytics'
import { submitFeedback } from 'net/airtable'
import { useUserProvider } from 'providers/UserProvider'
import { useCallback, useEffect, useRef, useState } from 'react'
import ResizeTextarea from 'react-textarea-autosize'
import { User } from 'types/User'
import ErrorBox from 'ui/core/ErrorBox'
import { Feedback } from 'types/Feedback'
import { isValidEmail } from 'util/email'
import { kCreditsForFeedback } from 'constants/defaults'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const kStartYear = new Date().getFullYear() - 13

const FreeCreditModal = ({ isOpen, onClose }: Props) => {
  const { user, updateUser, addCredits } = useUserProvider()
  const inputNameRef = useRef<HTMLInputElement>(null)
  const inputEmailRef = useRef<HTMLInputElement>(null)
  const inputBirthYearRef = useRef<HTMLSelectElement>(null)
  const inputGenderRef = useRef<HTMLSelectElement>(null)
  const inputFeedbackRef = useRef<HTMLTextAreaElement>(null)
  const checkboxRef = useRef<HTMLInputElement>(null)

  const [errors, setErrors] = useState<string[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    Analytics.trackEvent('modal.freeCredits.view')
  }, [])

  const handleSubmit = useCallback(async () => {
    if (!user) {
      return
    }

    setErrors([])

    const name = inputNameRef.current?.value
    const email = inputEmailRef.current?.value
    const birthYear = inputBirthYearRef.current?.value
    const gender = inputGenderRef.current?.value
    const feedback = inputFeedbackRef.current?.value ?? ''
    const contactable = checkboxRef.current?.checked ?? false

    // Validate the user input
    const userData: User = {
      ...user,
      email: email,
      profile: {
        name,
        birthYear: Number(birthYear),
        gender: gender && isValidGender(gender) ? gender : undefined,
      },
    }

    const validationErrors = validateProfile(userData)
    if (validationErrors.length > 0) {
      setErrors(validationErrors)
      return
    }

    // If all looks good, submit the feedback and update the user

    const feedbackObject: Feedback = {
      feedback,
      contactable,
      user: userData,
    }

    Analytics.trackEvent('modal.freeCredits.submit')
    setIsSubmitting(true)

    submitFeedback(feedbackObject).then((resp) => {
      setIsSubmitting(false)
      if (resp.error) {
        Analytics.trackEvent('modal.freeCredits.submit.error', {
          error: resp.error.message,
        })
        setErrors([resp.error.message])
      } else {
        Analytics.trackEvent('modal.freeCredits.submit.success')
        updateUser(userData)
        addCredits(kCreditsForFeedback)
        onClose()
      }
    })
  }, [addCredits, onClose, updateUser, user])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      preserveScrollBarGap
      size={{ base: 'full', md: 'md' }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      initialFocusRef={undefined}
      scrollBehavior='inside'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>✨ Receive {kCreditsForFeedback} free credits</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody fontSize='lg' p={0}>
          <Box p={6}>
            <Text>
              Tell us a little about yourself to receive free credits to use
              with any InnerMuse counselor.
            </Text>
            <VStack mt={6} align='start'>
              {errors.length > 0 &&
                errors.map((e) => <ErrorBox key={e}>{e}</ErrorBox>)}
              <Input ref={inputNameRef} placeholder='Your name' size='lg' />
              <Input
                ref={inputEmailRef}
                placeholder='Email'
                type='email'
                size='lg'
                defaultValue={user?.email}
              />
              <Flex gap={2} w='full'>
                <Select ref={inputBirthYearRef} size='lg' flex={1}>
                  <option disabled selected>
                    Birth year
                  </option>
                  {Array.from({ length: 100 }, (_, i) => {
                    const year = kStartYear - i
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    )
                  })}
                </Select>
                <Select ref={inputGenderRef} size='lg' flex={1}>
                  <option disabled selected value={undefined}>
                    Gender
                  </option>
                  <option value='male'>Male</option>
                  <option value='female'>Female</option>
                  <option value='non-binary'>Non-binary</option>
                  <option value='abstain'>Prefer not to say</option>
                  <option value='other'>Other</option>
                </Select>
              </Flex>
              <Textarea
                ref={inputFeedbackRef}
                placeholder='How has your experience been so far?'
                size='lg'
                as={ResizeTextarea}
                minRows={3}
              />
              <Checkbox
                ref={checkboxRef}
                defaultChecked
                color='gray.500'
                colorScheme='gray'
                pt={2}
              >
                Allow the InnerMuse team to reach out
              </Checkbox>
            </VStack>
          </Box>
          <Divider />
          <Box p={6}>
            <Button
              variant='primary'
              w='full'
              size='lg'
              onClick={handleSubmit}
              isLoading={isSubmitting}
            >
              Redeem {kCreditsForFeedback} credits
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const validateProfile = (user: User): string[] => {
  const errors: string[] = []

  if (!user.profile?.name) {
    errors.push('Please enter your name')
  }
  if (!user.email || !isValidEmail(user.email)) {
    errors.push('Please enter a valid email')
  }
  if (!user.profile?.birthYear) {
    errors.push('Please enter your birth year')
  }
  if (!user?.profile?.gender || !isValidGender(user.profile.gender)) {
    errors.push('Please specify your gender identitity')
  }

  return errors
}

const isValidGender = (value: string): boolean =>
  Object.values(kGenders).includes(value)

export default FreeCreditModal
