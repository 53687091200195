import { Flex, FlexProps, Text, useDisclosure } from '@chakra-ui/react'
import useLocaleString from 'hooks/useLocalString'
import Analytics from 'lib/analytics'
import { useUserProvider } from 'providers/UserProvider'
import { BiChevronRight } from 'react-icons/bi'
import FreeCreditModal from 'ui/shared/FreeCreditModal'

type Props = {
  priceId: string
  price: number
  credits: number
  topic?: string
  analyticsSource?: string
}

const CreditBox = ({
  price,
  credits,
  priceId,
  topic,
  analyticsSource,
}: Props) => {
  const { initiateCheckout } = useUserProvider()

  // Localized strings
  const creditsString = useLocaleString('credits')

  const handleClick = () => {
    Analytics.trackEvent('payments.credits.buy', {
      source: analyticsSource,
      price,
      credits,
      priceId,
      topic,
    })
    initiateCheckout(priceId, topic)
  }

  return (
    <CreditBoxContainer
      onClick={handleClick}
      bg='blue.100'
      _hover={{
        base: 'blue.100',
        md: {
          bg: 'blue.200',
        },
      }}
    >
      <Text fontWeight={500} fontSize='16px' color='blue.900'>
        {credits} {creditsString}
      </Text>
      <Text color='blue.500'>${price}</Text>
      <BiChevronRight fill='blue' />
    </CreditBoxContainer>
  )
}

export const FreeCreditBox = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClick = () => {
    onOpen()
  }

  return (
    <>
      <CreditBoxContainer
        bg='green.600'
        _hover={{ bg: 'green.800' }}
        onClick={handleClick}
      >
        <Text fontWeight={500} fontSize='16px' color='white'>
          Get free credits
        </Text>
        <BiChevronRight fill='white' />
      </CreditBoxContainer>
      <FreeCreditModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}

const CreditBoxContainer = ({ children, onClick, ...props }: FlexProps) => {
  return (
    <Flex
      px={2}
      py={1}
      rounded='md'
      align='center'
      gap={2}
      onClick={onClick}
      cursor='pointer'
      {...props}
    >
      {children}
    </Flex>
  )
}

export default CreditBox
