import { fetchNextApi } from 'net/api'
import { Feedback } from 'types/Feedback'

/**
 * Sends feedback to an Airtable base
 * @param entries
 * @returns
 */
export const submitFeedback = (feedback: Feedback) => {
  return fetchNextApi('/api/feedback/send', {
    method: 'POST',
    body: JSON.stringify(feedback),
  })
}
