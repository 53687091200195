import { Select, useToast } from '@chakra-ui/react'
import { kLanguages } from 'l10n/languages'
import { kLocaleString } from 'l10n/strings'
import Analytics from 'lib/analytics'
import { useUserProvider } from 'providers/UserProvider'
import { useCallback } from 'react'

const LanguageSelector = () => {
  const { user, updateUser } = useUserProvider()
  const toast = useToast()

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLSelectElement>) => {
      const language = event.target.value
      await updateUser({
        locale: language,
      })
      const successString =
        kLocaleString['Language updated'][language] ?? 'Language updated'
      toast({
        position: 'top',
        title: successString,
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      Analytics.trackEvent('settings.language.change', { language })
    },
    [toast, updateUser]
  )

  if (!user) {
    return null
  }

  return (
    <Select
      defaultValue={user.locale}
      onChange={handleChange}
      w='fit-content'
      bg='white'
      data-testid='language-selector'
    >
      {kLanguages.map((language) => (
        <option key={language.code} value={language.code}>
          {language.name}
        </option>
      ))}
    </Select>
  )
}

export default LanguageSelector
