import { kLocaleString } from 'l10n/strings'
import { useUserProvider } from 'providers/UserProvider'

const useLocaleString = (string: string, param?: any): string => {
  const { user } = useUserProvider()
  const locale = user?.locale

  return getLocaleString(string, locale, param)
}

export const useLocaleStringMany = (
  strings: string[],
  param?: any
): string[] => {
  const { user } = useUserProvider()
  const locale = user?.locale

  return strings.map((s) => getLocaleString(s, locale, param))
}

export const getLocaleString = (string: string, locale = 'en', param?: any) => {
  if (!locale || locale === 'en') {
    return string
  }
  let translation: string

  // Check for dynamic translation first
  if (param && typeof kLocaleString[string] === 'function') {
    translation = kLocaleString[string](param)?.[locale]
  } else {
    translation = kLocaleString[string]?.[locale]
  }

  if (!translation) {
    return string
  }

  return translation
}

export default useLocaleString
