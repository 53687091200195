export const kLocaleString: Record<string, any> = {}

/**
 * Chat experience
 */

kLocaleString[
  "Hi there, how are you feeling today about your relationship? Is there anything specific you'd like to talk about?"
] = {
  zh: '你好，你今天对你的关系感觉如何？你想谈论什么特定的事情吗？',
  es: 'Hola, ¿cómo te sientes hoy acerca de tu relación? ¿Hay algo específico que te gustaría hablar?',
  hi: 'नमस्ते, आप अपने संबंधों के बारे में आज कैसा महसूस कर रहे हैं? क्या आप बात करना चाहेंगे?',
  pt: 'Olá, como você se sente hoje em relação ao seu relacionamento? Há algo específico que você gostaria de falar?',
  bn: 'হাই থারে, আপনি আপনার সম্পর্কের সম্পর্কে আজ কী ভাবে মনে করছেন? কোনও নির্দিষ্ট বিষয় আপনি কথা বলতে চান?',
  ru: 'Привет, как вы сегодня чувствуете себя по поводу вашего отношения? Есть ли что-то конкретное, о чем вы хотели бы поговорить?',
  ja: 'こんにちは、あなたは今日、あなたの関係についてどのように感じていますか？話したい特定のことはありますか？',
  yue: '嗨，你今天對你嘅關係有咩感覺？有冇咩特別想傾傾？',
  vi: 'Xin chào, bạn cảm thấy thế nào về mối quan hệ của mình hôm nay? Có điều gì cụ thể mà bạn muốn nói chuyện không?',
  tr: 'Merhaba, bugün ilişkin hakkında nasıl hissediyorsunuz? Konuşmak istediğiniz özel bir şey var mı?',
  wuu: '嗨，您今日对您个恋爱怎样感觉？有冇啥特别想讲嘅事？',
  mr: 'हाय तोंड, आपण आपल्या संबंधांबद्दल आज कसे वाटत आहात? काहीतरी विशेष बोलायचे आहे का?',
  te: 'హాయ్ థేర్, మీరు మీ సంబంధం గురించి నేను నేడు ఎలా అనుభవిస్తున్నారు? మీరు చాలా స్పష్టంగా మాట్లాడాలారా?',
  ko: '안녕하세요, 오늘 당신은 당신의 관계에 대해 어떻게 느끼고 있나요? 이야기하고 싶은 구체적인 것이 있나요?',
  fr: "Bonjour, comment vous sentez-vous aujourd'hui par rapport à votre relation? Y a-t-il quelque chose de spécifique que vous aimeriez discuter?",
  ta: 'ஹாய் தேரே, உங்கள் உறவுகளுக்கு இன்னும் நீங்கள் இன்னும் எப்படி உணருகின்றீர்கள்? உங்களுக்கு பேச வேண்டிய எந்த குறிப்புகள் உள்ளன?',
  arz: 'مرحبا، كيف تشعر اليوم بشأن علاقتك؟ هل هناك أي شيء محدد تود الحديث عنه؟',
  de: 'Hallo, wie fühlst du dich heute bezüglich deiner Beziehung? Gibt es etwas Bestimmtes, über das du sprechen möchtest?',
  ur: 'ہائے، آپ اپنے تعلقات کے بارے میں آج کیسا محسوس کر رہے ہیں؟ کیا آپ کو کچھ خاص گفتگو کرنا چاہتے ہیں؟',
  jv: 'Hi nang, piye kabarmu saiki babagan hubunganmu? Ana apa sing spesifik sing pengin kowe omongake?',
  pa: 'ਹਾਇ ਥੇਰ, ਤੁਸੀਂ ਆਪਣੇ ਰਿਸ਼ਤਿਆਂ ਬਾਰੇ ਅੱਜ ਕੀਵੇਂ ਮਹਿਸੂਸ ਕਰ ਰਹੇ ਹੋ? ਕੀ ਤੁਸੀਂ ਕੁਝ ਖਾਸ ਗੱਲਾਂ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?',
  it: "Ciao, come ti senti oggi riguardo alla tua relazione? C'è qualcosa di specifico di cui vorresti parlare?",
  gu: 'હાય થેર, તમે તમારા સંબંધો વિશે આજે કેવી રીતે અનુભવો છો? શું તમે કંઈ ખાસ વાતો કરવા માંગતા હો?',
  fa: 'سلام، امروز درباره رابطه خود چطور احساس می‌کنید؟ آیا چیز خاصی وجود دارد که می‌خواهید درباره آن صحبت کنید؟',
  bho: 'नमस्ते, कइल बात आपन के सम्बन्ध में कैसन लगल बा? कइये खास बात आप बोलना चाहत हो?',
  ha: 'Sannu, yaya kake ciki har zuwa game da lambobin ku? Akwai abin da zaka so mu yi magana?',
}

kLocaleString[
  'A reliable and unbiased source of guidance and support. Available 24/7, anywhere in the world.'
] = {
  zh: '一個可靠且公正的指導和支持來源。全天候提供，在世界任何地方都可用。',
  es: 'Una fuente confiable y sin prejuicios de orientación y apoyo. Disponible las 24 horas del día, en cualquier lugar del mundo.',
  hi: 'एक विश्वसनीय और निष्पक्ष मार्गदर्शन और समर्थन का स्रोत। दुनिया भर में कहीं भी 24/7 उपलब्ध है।',
  pt: 'Uma fonte confiável e sin prejuicios de orientação e suporte. Disponível 24/7, em qualquer lugar do mundo.',
  bn: 'একটি নির্ভুল এবং নিরপেক্ষ পরামর্শ এবং সমর্থনের একটি বিশ্বব্যাপী উপাদান। বিশ্বের যেকোন জায়গায় 24/7 উপলব্ধ।',
  ru: 'Надежный и беспристрастный источник руководства и поддержки. Доступен 24/7 в любой точке мира.',
  ja: '信頼できるかつ偏見のない指導とサポートの源。世界中で24/7利用可能。',
  yue: '一個可靠同公正嘅指導同支援來源。全日24小時，喺世界任何一個地方都得。',
  vi: 'Một nguồn hướng dẫn và hỗ trợ đáng tin cậy và không thiên vị. Có sẵn 24/7, bất cứ nơi đâu trên thế giới.',
  tr: 'Güvenilir ve tarafsız bir rehberlik ve destek kaynağı. Dünya genelinde her yerde 7/24 kullanılabilir.',
  wuu: '一个可靠同公正嘅指导同支援来源。全日24小时，喺世界任何一个地方都得。',
  mr: 'एक विश्वसनीय आणि बेतोवास्तव अभिमुख आणि समर्थनाची स्रोत. जगभर 24/7 उपलब्ध आहे.',
  te: 'గుర్తించబడిన మరియు పక్షపాతరహిత మార్గదర్శన మరియు మద్దతు యొక్క ఒక ఖచ్చితమైన మూలం. ప్రపంచవ్యాప్తంగా ఎక్కడావో ఉంటారు 24/7.',
  ko: '신뢰할 수 있고 편견없는 지도와 지원의 원천입니다. 전 세계 어디에서나 24/7 이용 가능합니다.',
  fr: "Une source fiable et impartiale de conseils et de soutien. Disponible 24/7, n'importe où dans le monde.",
  ta: 'ஒரு நம்பகமான மற்றும் சரியான வழிகாட்டி மற்றும் ஆதரவு மூலம். உலகளாவியமாக 24/7, எங்கும் எங்கும் கிடைக்கின்றன.',
  arz: 'مصدر موثوق وغير منحاز من الإرشاد والدعم. متاح على مدار 24 ساعة ، في أي مكان في العالم.',
  de: 'Eine zuverlässige und unvoreingenommene Quelle für Anleitung und Unterstützung. Rund um die Uhr und überall auf der Welt verfügbar.',
  ur: 'ایک قابل اعتماد اور بے پاک راہنمائی اور حمایت کا ذریعہ. دنیا بھر میں کہیں بھی 24/7 دستیاب ہے۔',
  jv: 'Sumber pangarep lan dukungan sing andal lan ora bias. Tersedia 24/7, ana ing saindenging jagad.',
  pa: 'ਏਕ ਭਰੋਸੇਯੋਗ ਅਤੇ ਬਿਨਾਅਪਾ ਹਦਾਇਤ ਅਤੇ ਸਹਿਯੋਗ ਦਾ ਸਰੋਤ। ਦੁਨੀਆ ਭਰ ਵਿੱਚ ਕਿੱਥੇ ਵੀ 24/7 ਉਪਲਬਧ ਹੈ।',
  it: 'Una fonte affidabile e imparziale di orientamento e supporto. Disponibile 24/7, ovunque nel mondo.',
  gu: 'એક વિશ્વસનીય અને બિન-પક્ષપાતી માર્ગદર્શન અને સહાય સ્રોત. દુનિયા ભરમાં કોઈપણ સ્થળે 24/7 ઉપલબ્ધ છે.',
  fa: 'منبعی قابل اعتماد و بی طرفانه از راهنمایی و پشتیبانی. در هر ساعت از شبانه‌روز، در هر نقطه‌ای از جهان در دسترس است.',
  bho: 'एक विश्वसनीय तथा तटस्थ मार्गदर्शन तथा समर्थन का स्रोत। दुनिया भर में कब भी और कहीं भी उपलब्ध है।',
  ha: 'Mafi sauki da kuma sadarwar da babban datafi na taimako. Akwai 24/7, kowane lokaci a duniya.',
}

kLocaleString['What would you like support with today?'] = {
  zh: '你今天需要什么帮助？',
  es: '¿En qué puedo ayudarte hoy?',
  hi: 'आज आप किस चीज में मदद चाहते हैं?',
  pt: 'Em que posso ajudá-lo hoje?',
  bn: 'আজ আপনি কী সাহায্য চান?',
  ru: 'Чем я могу вам помочь сегодня?',
  ja: '今日は何をお手伝いできますか？',
  yue: '你想今日有咩幫助？',
  vi: 'Hôm nay bạn cần giúp gì?',
  tr: 'Bugün ne konuda yardıma ihtiyacınız var?',
  wuu: '侬有咩事需要帮忙？',
  mr: 'आज आपल्याला काय मदतीची गरज आहे?',
  te: 'ఈ రోజు మీరు ఏమి సహాయం కావాలి?',
  ko: '오늘 무엇을 도와드릴까요?',
  fr: "En quoi puis-je vous aider aujourd'hui ?",
  ta: 'இன்று நீங்கள் என்ன உதவ வேண்டும்?',
  arz: 'تحتاج مساعدة فى شئ النهاردة؟',
  de: 'Wobei kann ich Ihnen heute helfen?',
  ur: 'آج آپ کو کس بات کی مدد درکار ہے؟',
  jv: 'Apa sing bisa aku tulung saiki?',
  pa: 'ਆਜ ਤੁਸੀਂ ਕਿਹੜੇ ਮਦਦ ਦੀ ਲੋੜ ਹੈ?',
  it: 'In cosa posso aiutarti oggi?',
  gu: 'આજે તમને શું મદદ જરૂરી છે?',
  fa: 'امروز در چه موردی به شما کمک کنم؟',
  bho: 'आज तुमके कई भांति मदद चाहत बा?',
  ha: 'Shin kake bukatar taimako a yau?',
}

kLocaleString['Hi! What brings you here today?'] = {
  zh: '嗨，今天你来这里是为了什么？',
  es: 'Hola, ¿qué te trae por aquí hoy?',
  hi: 'नमस्ते, आज आप यहाँ क्यों हैं?',
  pt: 'Olá, o que te traz aqui hoje?',
  bn: 'হাই, আজ আপনি কি এখানে আছেন?',
  ru: 'Здравствуйте, что привело вас сюда сегодня?',
  ja: 'こんにちは、今日は何のご用ですか？',
  yue: '嗨，你今日黎到呢度有咩事？',
  vi: 'Xin chào, hôm nay bạn đến đây vì điều gì?',
  tr: 'Merhaba, bugün buraya ne getirdi sizi?',
  wuu: '嘿，侬今日来侬个所里有咩事？',
  mr: 'हाय, आज तुम्ही येथे काय आहात?',
  te: 'హాయ్, నేను నేడు ఇక్కడ ఏం కలిసింది?',
  ko: '안녕하세요, 오늘 여기 오신 이유가 뭔가요?',
  fr: "Bonjour, qu'est-ce qui vous amène ici aujourd'hui ?",
  ta: 'வணக்கம், இன்று இங்கு என்ன வருகிறீர்கள்?',
  arz: 'اهلا، ايه اللي جابك هنا النهاردة؟',
  de: 'Hallo, was bringt Sie heute hierher?',
  ur: 'ہیلو، آج آپ یہاں کیوں ہیں؟',
  jv: 'Hai, apa sing ngamehake sampeyan menyang kene saiki?',
  pa: 'ਹਾਇ, ਆਜ ਤੁਸੀਂ ਇੱਥੇ ਕਿਹੜੇ ਲਈ ਆਏ ਹੋ?',
  it: 'Ciao, cosa ti porta qui oggi?',
  gu: 'હાય, આજ તમે કેટલાક કારણે અહીં છો?',
  fa: 'سلام، امروز چه کاری به اینجا آوردتون؟',
  bho: 'नमस्ते, आज आपने यहाँ क्या काम करने आये हैं?',
  ha: 'Sannu, yau muna bukatar ka hawniyar nan?',
}

kLocaleString['Message'] = {
  zh: '消息',
  es: 'Mensaje',
  hi: 'संदेश',
  pt: 'Mensagem',
  bn: 'বার্তা',
  ru: 'Сообщение',
  ja: 'メッセージ',
  yue: '訊息',
  vi: 'Tin nhắn',
  tr: 'Mesaj',
  wuu: '消息',
  mr: 'संदेश',
  te: 'సందేశం',
  ko: '메시지',
  fr: 'Message',
  ta: 'செய்தி',
  arz: 'رسالة',
  de: 'Nachricht',
  ur: 'پیغام',
  jv: 'Pesen',
  pa: 'ਸੁਨੇਹਾ',
  it: 'Messaggio',
  gu: 'સંદેશ',
  fa: 'پیام',
  bho: 'सन्देश',
  ha: 'Layi',
}

/**
 * Payments
 */

kLocaleString['Buy more credits'] = {
  zh: '购买更多积分',
  es: 'Comprar más créditos',
  hi: 'अधिक क्रेडिट खरीदें',
  pt: 'Compre mais créditos',
  bn: 'আরও ক্রেডিট কিনুন',
  ru: 'Купить больше кредитов',
  ja: 'もっとクレジットを購入する',
  yue: '買多啲積分',
  vi: 'Mua nhiều hơn các khoản tín dụng',
  tr: 'Daha fazla kredi satın alın',
  wuu: '买更多积分',
  mr: 'अधिक क्रेडिट वढवा',
  te: 'మరింత క్రెడిట్లు కొనండి',
  ko: '더 많은 크레딧 구입',
  fr: 'Acheter plus de crédits',
  ta: 'மேலும் கிரெடிடுகளை வாங்கவும்',
  arz: 'شراء المزيد من الائتمانات',
  de: 'Kaufen Sie mehr Credits',
  ur: 'زیادہ کریڈٹ خریدیں',
  jv: 'Beli luwih banter kredit',
  pa: 'ਹੋਰ ਕ੍ਰੈਡਿਟਾਂ ਖਰੀਦੋ',
  it: 'Acquista più crediti',
  gu: 'વધુ ક્રેડિટ્સ ખરીદો',
  fa: 'خرید بیشتر اعتبار',
  bho: 'और क्रेडिट खरीदें',
  ha: 'Sai wata karin kudaden kudade',
}

kLocaleString[
  "You're out of credits. Please purchase more credits to continue the conversation."
] = {
  zh: '您的信用点已用完，请购买更多信用点以继续会话。',
  es: 'Se ha quedado sin créditos. Por favor, compre más créditos para continuar la conversación.',
  hi: 'आपके क्रेडिट समाप्त हो गए हैं। कृपया चैट जारी रखने के लिए अधिक क्रेडिट खरीदें।',
  pt: 'Você está sem créditos. Por favor, compre mais créditos para continuar a conversa.',
  bn: 'আপনি ক্রেডিট শেষ হয়ে গেছেন। আলাপ চালিয়ে যাওয়ার জন্য আরও ক্রেডিট কিনুন।',
  ru: 'У вас закончились кредиты. Пожалуйста, купите больше кредитов, чтобы продолжить разговор.',
  ja: 'クレジットが不足しています。 会話を続けるには、クレジットを購入してください。',
  yue: '你嘅信貸已經用晒。請購買多啲信貸以繼續對話。',
  vi: 'Bạn đã hết tín dụng. Vui lòng mua thêm tín dụng để tiếp tục cuộc trò chuyện.',
  tr: 'Kredileriniz tükendi. Lütfen sohbeti devam ettirmek için daha fazla kredi satın alın.',
  wuu: '你嘅信用额度已经用晒。请购买多啲信用额度来继续对话。',
  mr: 'तुमच्या क्रेडिट पूर्ण झाले आहेत. कृपया चर्चा सुरू ठेवण्यासाठी अधिक क्रेडिट खरेदी करा.',
  te: 'మీ క్రెడిట్ చాలెంజ్ అయింది. చాట్ కనెక్ట్ కాయడానికి మరింత క్రెడిట్లు కొనండి.',
  ko: '크레딧이 부족합니다. 대화를 계속하려면 더 많은 크레딧을 구매하십시오.',
  fr: "Vous n'avez plus de crédits. Veuillez acheter plus de crédits pour continuer la conversation.",
  ta: 'உங்கள் கிரெடிட்கள் முடிவு செய்யப்பட்டுள்ளன. உரையாடலை தொடர்ந்து வைத்திருக்க மேலும் கிரெடிட்களை வாங்கவும்.',
  arz: 'لقد نفدت رصيدك. يرجى شراء المزيد من الرصيد لمواصلة المحادثة.',
  de: 'Ihre Credits sind aufgebraucht. Bitte kaufen Sie mehr Credits, um das Gespräch fortzusetzen.',
  ur: 'آپ کے کریڈٹ ختم ہوگئے ہیں۔ براہ کرم بات چیت جاری رکھنے کے لیے مزید کریڈٹ خریدیں۔',
  jv: 'Sampeyan wis habis kredit. Mangga tuku kredit luwih adhine kanggo nindakake omongan.',
  pa: 'ਤੁਸੀਂ ਕ੍ਰੈਡਿਟ ਖ਼ਤਮ ਹੋ ਗਏ ਹੋ। ਕਿਰਪਾ ਕਰਕੇ ਬਾਤਚੀਤ ਜਾਰੀ ਰੱਖਣ ਲਈ ਹੋਰ ਕ੍ਰੈਡਿਟ ਖਰੀਦੋ।',
  it: 'I tuoi crediti sono esauriti. Acquista ulteriori crediti per continuare la conversazione.',
  gu: 'તમારા ક્રેડિટ સમાપ્ત થઈ છે. ચેટ ચાલુ રાખવા માટે વધુ ક્રેડિટ્સ ખરીદો.',
  fa: 'شما از اعتبار خود خارج شده اید. لطفاً برای ادامه گفتگو بیشتر اعتبار بخرید.',
  bho: 'तुमके क्रेडिट खत्म हो गइल बा। कृपया चैट जारी रखने के लिए और क्रेडिट खरीदीं।',
  ha: 'Ka karbi katin kredi. Da fatan za a sami kudin kudi domin samun harsuna.',
}

kLocaleString['1 credit per message received'] = {
  zh: '每收到一条消息1个积分',
  es: '1 crédito por mensaje recibido',
  hi: 'प्राप्त हुए प्रति संदेश 1 क्रेडिट',
  pt: '1 crédito por mensagem recebida',
  bn: 'প্রতি বার পাঠানো বার্তার জন্য 1 ক্রেডিট',
  ru: '1 кредит за полученное сообщение',
  ja: '受信したメッセージ1件につき1クレジット',
  yue: '每條收到嘅訊息1個積分',
  vi: '1 tín nhắn nhận được tương ứng với 1 đơn vị tín dụng',
  tr: 'Her alınan mesaj başına 1 kredi',
  wuu: '每收到一條訊息1個積分',
  mr: 'प्रत्येक संदेशासाठी 1 क्रेडिट प्राप्त करा',
  te: 'సందేశం పొందిన ప్రతి సందేశం క్రెడిట్ 1',
  ko: '받은 메시지 당 1 크레딧',
  fr: '1 crédit par message reçu',
  ta: 'ஒரு செய்தியைப் பெறும் போது 1 கிரெடிட்',
  arz: '1 كريديت لكل رسالة تم استلامها',
  de: '1 Guthaben pro empfangener Nachricht',
  ur: 'ہر پیغام حاصل کرنے پر 1 کریڈٹ',
  jv: '1 kredit sak message sing diterima',
  pa: 'ਪ੍ਰਤੀ ਸੁਨੇਹਾ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ 1 ਕ੍ਰੈਡਿਟ',
  it: '1 credito per ogni messaggio ricevuto',
  gu: 'પ્રતિ સંદેશને 1 ક્રેડિટ મેળવો',
  fa: '1 اعتبار برای هر پیام دریافتی',
  bho: 'प्रति संदेश 1 क्रेडिट प्राप्त करें',
  ha: '1 katin kudi game da duk da aka sami',
}

kLocaleString['Basic AI: 1 credit per message received'] = {
  zh: '基本AI：每收到一条消息1学分',
  es: 'IA básica: 1 crédito por cada mensaje recibido',
  hi: 'बेसिक एआई: प्राप्त हुए प्रत्येक संदेश पर 1 क्रेडिट',
  pt: 'IA básica: 1 crédito por cada mensagem recebida',
  bn: 'বেসিক এআই: প্রাপ্ত করা মেসেজ প্রতি ক্রেডিট 1',
  ru: 'Базовый ИИ: 1 кредит за каждое полученное сообщение',
  ja: '基本的なAI：受信したメッセージごとに1クレジット',
  yue: '基本人工智能：每條收到嘅消息1學分',
  vi: 'Trí tuệ nhân tạo cơ bản: 1 tín chỉ cho mỗi tin nhắn nhận được',
  tr: 'Temel AI: alınan her mesaj için 1 kredi',
  wuu: '基本人工智能：每条收到嘅讯息1学分',
  mr: 'मूलभूत AI: प्रत्येक संदेशासाठी 1 क्रेडिट मिळविणे',
  te: 'బేసిక్ ఎయి: పొందిన ప్రతి సందేశం క్రెడిట్ కొనుగోలు 1',
  ko: '기본 인공지능 :받은 메시지 당 1 크레딧',
  fr: 'IA de base: 1 crédit par message reçu',
  ta: 'அடிப்படை ஐஐ: பரிமாற்றம் பெற்ற ஒவ்வொரு செய்திக்கும் 1 கிரெடிட்',
  arz: 'ذكاء اصطناعى بسيط: 1 رصيد لكل رسالة تم استلامها',
  de: 'Grundlegende KI: 1 Guthaben pro empfangener Nachricht',
  ur: 'بنیادی اے آئی: پیغامات کے ہر وصول کے لئے 1 کریڈٹ',
  jv: 'AI dasar: 1 kredit nang nduweni pesen',
  pa: 'ਬੇਸਿਕ ਐਆਈ: ਹਰ ਸੁਨੇਹੇ ਲਈ 1 ਕਰੇਡਿਟ',
  it: 'IA di base: 1 credito per ogni messaggio ricevuto',
  gu: 'મૂળભૂત એઆઈ: પ્રતિ સંદેશ પ્રાપ્ત કર્યા પર 1 ક્રેડિટ',
  fa: 'هوش مصنوعی پایه: ۱ اعتبار برای هر پیام دریافتی',
  bho: 'बुनियादी एआई: प्राप्त संदेश प्रति 1 क्रेडिट',
  ha: 'AI na gaba: 1 kudin da aka samu kowane hira',
}

kLocaleString['Advanced AI: 2 credits per message received'] = {
  zh: '高级 AI：每条收到的消息 2 积分',
  es: 'IA avanzada: 2 créditos por mensaje recibido',
  hi: 'उन्नत AI: प्रति संदेश प्राप्त करने पर 2 क्रेडिट',
  pt: 'IA avançada: 2 créditos por mensagem recebida',
  bn: 'উন্নয়নমূলক এআই: প্রতি বার্তা পাওয়া যায় 2 ক্রেডিট',
  ru: 'Продвинутый ИИ: 2 кредита за полученное сообщение',
  ja: '高度なAI：受信したメッセージごとに2クレジット',
  yue: '高級 AI：每個收到嘅訊息 2 學分',
  vi: 'Trí tuệ nhân tạo tiên tiến: 2 tín dụng cho mỗi tin nhắn nhận được',
  tr: 'Gelişmiş AI: her alınan mesaj için 2 kredi',
  wuu: '高级 AI：每个收到嘅讯息 2 学分',
  mr: 'उन्नत AI: प्रत्येक संदेशासाठी 2 क्रेडिट',
  te: 'అడ్వాన్స్డ్ ఎయి: ప్రతి సందేశం స్వీకరించిన ప్రతి సందేశం క్రెడిట్లు 2',
  ko: '고급 AI: 받은 메시지 당 2 크레딧',
  fr: 'IA avancée : 2 crédits par message reçu',
  ta: 'மேம்பட்ட ஐஎ : புகுபதிக்கப்பட்ட ஒவ்வொரு செய்தி முன்பு 2 கிரெடிட்',
  arz: 'ذكاء اصطناعى متقدم: 2 رصيد لكل رسالة تم استلامها',
  de: 'Fortgeschrittene KI: 2 Credits pro empfangener Nachricht',
  ur: 'ایڈوانسڈ اے آئی: ہر وصول کردہ پیغام کے لئے 2 کریڈٹس',
  jv: 'AI Lanjutan: 2 kredit sak pesen sing diterima',
  pa: "ਤਰੱਕੀ ਪਾਈ: ਪ੍ਰਤੀ ਸੁਨੇਹਾ ਪ੍ਰਾਪਤ ਕਰਨ 'ਤੇ 2 ਕਰੈਡਿਟ",
  it: 'IA avanzata: 2 crediti per messaggio ricevuto',
  gu: 'એડવાન્સ એઆઈ: પ્રતિ સંદેશ પ્રાપ્ત કર્યા પર 2 ક્રેડિટ્સ',
  fa: 'هوش مصنوعی پیشرفته: 2 اعتبار برای هر پیام دریافتی',
  bho: 'आगे बढ़ी एआई: प्रति संदेश प्राप्त करने पर 2 क्रेडिट',
  ha: 'Bayanin AI: Kowane lamba da aka samu credit 2',
}

kLocaleString['credits'] = {
  zh: '积分',
  es: 'créditos',
  hi: 'क्रेडिट',
  pt: 'créditos',
  bn: 'ক্রেডিট',
  ru: 'кредитов',
  ja: 'クレジット',
  yue: '個積分',
  vi: 'tín dụng',
  tr: 'kredi',
  wuu: '学分',
  mr: 'क्रेडिट',
  te: 'క్రెడిట్స్',
  ko: '크레딧',
  fr: 'crédits',
  ta: 'கிரெடிட்கள்',
  arz: 'اعتماد',
  de: 'Credits',
  ur: 'کریڈٹس',
  jv: 'kredit',
  pa: 'ਕਰੇਡਿਟ',
  it: 'crediti',
  gu: 'ક્રેડિટ્સ',
  fa: 'اعتبار',
  bho: 'क्रेडिट',
  ha: 'kudin guda',
}

kLocaleString['credit'] = {
  zh: '信用点',
  es: 'crédito',
  hi: 'क्रेडिट',
  pt: 'crédito',
  bn: 'ক্রেডিট',
  ru: 'кредит',
  ja: 'クレジット',
  yue: '個學分',
  vi: 'tín dụng',
  tr: 'kredi',
  wuu: '學分',
  mr: 'क्रेडिट',
  te: 'క్రెడిట్',
  ko: '크레딧',
  fr: 'crédit',
  ta: 'கிரெடிட்',
  arz: 'كريديت',
  de: 'Kredit',
  ur: 'کریڈٹ',
  jv: 'kredit',
  pa: 'ਕਰੇਡਿਟ',
  it: 'credito',
  gu: 'ક્રેડિટ',
  fa: 'اعتبار',
  bho: 'क्रेडिट',
  ha: 'kudin kaya',
}

kLocaleString['credits remaining'] = {
  zh: '剩余积分',
  es: 'créditos restantes',
  hi: 'शेष क्रेडिट',
  pt: 'créditos restantes',
  bn: 'বাকি ক্রেডিট',
  ru: 'осталось кредитов',
  ja: '残りのクレジット',
  yue: '剩餘儲值',
  vi: 'Tín dụng còn lại',
  tr: 'kalan krediler',
  wuu: '剩馀积分',
  mr: 'शिल्लक शिल्लिमित',
  te: 'క్రెడిట్లు మిగిలినవి',
  ko: '잔여 크레딧',
  fr: 'crédits restants',
  ta: 'மீதி கிடைக்கும் கடன்',
  arz: 'الرصيد المتبقي',
  de: 'verbleibende Credits',
  ur: 'کریڈٹ باقی',
  jv: 'sisa kredit',
  pa: 'ਬਾਕੀ ਕਰੇਡਿਟ',
  it: 'crediti rimanenti',
  gu: 'બાકી ક્રેડિટ્સ',
  fa: 'اعتبار باقی مانده',
  bho: 'शेष क्रेडिट',
  ha: 'Kudin kudi kudi',
}

/**
 * Support topics
 */
kLocaleString['Breakup'] = {
  zh: '分手',
  es: 'Ruptura',
  hi: 'ब्रेकअप',
  pt: 'Terminar Relacionamento',
  bn: 'বিচ্ছেদ',
  ru: 'Расставание',
  ja: '別れること',
  yue: '分手',
  vi: 'Chia tay',
  tr: 'Ayrılık',
  wuu: '分手',
  mr: 'ब्रेकअप',
  te: 'బ్రేకప్',
  ko: '이별',
  fr: 'Rupture',
  ta: 'பிரிக்கலாம்',
  arz: 'انفصال',
  de: 'Trennung',
  ur: 'تعلقات کا خاتمہ',
  jv: 'Putus',
  pa: 'ਬਿਛੜਨਾ',
  it: 'Rottura',
  gu: 'બ્રેકઅપ',
  fa: 'جدایی',
  bho: 'ब्रेकअप',
  ha: 'Hana',
}
kLocaleString['Dating'] = {
  zh: '约会',
  es: 'Citas',
  hi: 'डेटिंग',
  pt: 'Encontros',
  bn: 'ডেটিং',
  ru: 'Знакомства',
  ja: 'デート',
  yue: '拍拖',
  vi: 'Hẹn hò',
  tr: 'Tarihleme',
  wuu: '拍拖',
  mr: 'डेटिंग',
  te: 'డేటింగ్',
  ko: '데이트',
  fr: 'Rencontre',
  ta: 'டேட்டிங்',
  arz: 'مواعدة',
  de: 'Verabredung',
  ur: 'ڈیٹنگ',
  jv: 'Jaman',
  pa: 'ਡੇਟਿੰਗ',
  it: 'Appuntamenti',
  gu: 'ડેટિંગ',
  fa: 'قرار ملاقات',
  bho: 'डेटिंग',
  ha: 'Ranar rayuwa',
}
kLocaleString['Depression'] = {
  zh: '抑郁症',
  es: 'Depresión',
  hi: 'अवसाद',
  pt: 'Depressão',
  bn: 'বিষণ্ণতা',
  ru: 'Депрессия',
  ja: 'うつ病',
  yue: '憂鬱症',
  vi: 'Trầm cảm',
  tr: 'Depresyon',
  wuu: '抑鬱症',
  mr: 'उदासी',
  te: 'అవసాదం',
  ko: '우울증',
  fr: 'Dépression',
  ta: 'மன அழுத்தம்',
  arz: 'اكتئاب',
  de: 'Depression',
  ur: 'افسردگی',
  jv: 'Depresi',
  pa: 'ਉਦਾਸੀ',
  it: 'Depressione',
  gu: 'ઉદાસી',
  fa: 'افسردگی',
  bho: 'उदासी',
  ha: 'Depression',
}
kLocaleString['Anxiety'] = {
  zh: '焦虑',
  es: 'Ansiedad',
  hi: 'चिंता',
  pt: 'Ansiedade',
  bn: 'উদ্বেগ',
  ru: 'Тревога',
  ja: '不安',
  yue: '焦慮',
  vi: 'Lo âu',
  tr: 'Endişe',
  wuu: '焦虑',
  mr: 'घाबरणे',
  te: 'ఆందోళనం',
  ko: '불안',
  fr: 'Anxiété',
  ta: 'கவனமாகாதவை',
  arz: 'قلق',
  de: 'Angst',
  ur: 'تشویش',
  jv: 'Kawatir',
  pa: 'ਚਿੰਤਾ',
  it: 'Ansia',
  gu: 'ચિંતા',
  fa: 'اضطراب',
  bho: 'घबराहट',
  ha: 'Tsaukarar son gaba',
}
kLocaleString['Career'] = {
  zh: '职业',
  es: 'Carrera',
  hi: 'करियर',
  pt: 'Carreira',
  bn: 'করিয়ার',
  ru: 'Карьера',
  ja: 'キャリア',
  yue: '事業',
  vi: 'Sự nghiệp',
  tr: 'Kariyer',
  wuu: '事業',
  mr: 'करिअर',
  te: 'కరీర్',
  ko: '커리어',
  fr: 'Carrière',
  ta: 'பணியிடம்',
  arz: 'مهنة',
  de: 'Karriere',
  ur: 'کیریئر',
  jv: 'Karir',
  pa: 'ਕਰਿਅਰ',
  it: 'Carriera',
  gu: 'કરિયર',
  fa: 'حرفه',
  bho: 'ब्यवसाय',
  ha: 'Hali',
}
kLocaleString['School'] = {
  zh: '学校',
  es: 'Escuela',
  hi: 'विद्यालय',
  pt: 'Escola',
  bn: 'স্কুল',
  ru: 'Учёба',
  ja: '学校',
  yue: '學校',
  vi: 'Trường học',
  tr: 'Okul',
  wuu: '学校',
  mr: 'शाळा',
  te: 'పాఠశాల',
  ko: '학교',
  fr: 'École',
  ta: 'பள்ளி',
  arz: 'مدرسه',
  de: 'Schule',
  ur: 'اسکول',
  jv: 'Sekolah',
  pa: 'ਸਕੂਲ',
  it: 'Scuola',
  gu: 'શાળા',
  fa: 'مدرسه',
  bho: 'स्कूल',
  ha: 'Makaranta',
}

kLocaleString['Online'] = {
  zh: '在线',
  es: 'En línea',
  hi: 'ऑनलाइन',
  pt: 'Online',
  bn: 'অনলাইনে',
  ru: 'Онлайн',
  ja: 'オンライン',
  yue: '綫上',
  vi: 'Trực tuyến',
  tr: 'Çevrimiçi',
  wuu: '线上',
  mr: 'ऑनलाईन',
  te: 'ఆన్‌లైన్',
  ko: '온라인',
  fr: 'En ligne',
  ta: 'இணையத்தில்',
  arz: 'اون لاين',
  de: 'Online',
  ur: 'آن لائن',
  jv: 'Online',
  pa: 'ਆਨਲਾਈਨ',
  it: 'Online',
  gu: 'ઓનલાઈન',
  fa: 'آنلاین',
  bho: 'ऑनलाइन',
  ha: 'Online',
}

kLocaleString['Coming soon'] = {
  zh: '即将推出',
  es: 'Próximamente',
  hi: 'जल्द ही आ रहा है',
  pt: 'Em breve',
  bn: 'শীঘ্রই আসছে',
  ru: 'Скоро будет здесь',
  ja: '近日登場',
  yue: '即將推出',
  vi: 'Sắp ra mắt',
  tr: 'Yakında',
  wuu: '即将推出',
  mr: 'लवकरच येत आहे',
  te: 'త్వరలో రాబోతుంది',
  ko: '곧 출시',
  fr: 'Bientôt disponible',
  ta: 'விரைவில் வருகின்றது',
  arz: 'قريبا',
  de: 'In Kürze verfügbar',
  ur: 'جلد آرہا ہے',
  jv: 'Mundhak',
  pa: 'ਜਲਦੀ ਆ ਰਿਹਾ ਹੈ',
  it: 'Prossimamente',
  gu: 'ટક માટે આવી રહ્યું છે',
  fa: 'به زودی',
  bho: 'शीघ्र आवेला',
  ha: 'Yanzu haka',
}

/**
 * Settings
 */

kLocaleString['Language'] = {
  zh: '语言',
  es: 'Idioma',
  hi: 'भाषा',
  pt: 'Idioma',
  bn: 'ভাষা',
  ru: 'Язык',
  ja: '言語',
  yue: '語言',
  vi: 'Ngôn ngữ',
  tr: 'Dil',
  wuu: '语言',
  mr: 'भाषा',
  te: 'భాష',
  ko: '언어',
  fr: 'Langue',
  ta: 'மொழி',
  arz: 'لغه',
  de: 'Sprache',
  ur: 'زبان',
  jv: 'Basa',
  pa: 'ਭਾਸ਼ਾ',
  it: 'Lingua',
  gu: 'ભાષા',
  fa: 'زبان',
  bho: 'भाषा',
  ha: 'Harshe',
}

kLocaleString['Language updated'] = {
  zh: '语言已更新',
  es: 'Idioma actualizado',
  hi: 'भाषा अपडेट की गई',
  pt: 'Idioma atualizado',
  bn: 'ভাষা আপডেট হয়েছে',
  ru: 'Язык обновлен',
  ja: '言語が更新されました',
  yue: '語言已更新',
  vi: 'Ngôn ngữ đã được cập nhật',
  tr: 'Dil güncellendi',
  wuu: '语言已更新',
  mr: 'भाषा अद्यतनित केली',
  te: 'భాష నవీకరించబడింది',
  ko: '언어가 업데이트되었습니다',
  fr: 'Langue mise à jour',
  ta: 'மொழி புதுப்பிக்கப்பட்டது',
  arz: 'تم تحديث اللغة',
  de: 'Sprache aktualisiert',
  ur: 'زبان اپ ڈیٹ کر دی گئی ہے',
  jv: 'Basa dikiwari',
  pa: 'ਭਾਸ਼ਾ ਅੱਪਡੇਟ ਕੀਤੀ ਗਈ ਹੈ',
  it: 'Lingua aggiornata',
  gu: 'ભાષા અપડેટ કરવામાં આવી',
  fa: 'زبان به‌روز شده است',
  bho: 'भाषा अपडेट करल गेल बा',
  ha: 'Harshe ta kasance',
}

kLocaleString['Expertise Level'] = {
  zh: '专业水平',
  es: 'Nivel de experiencia',
  hi: 'विशेषज्ञता स्तर',
  pt: 'Nível de especialização',
  bn: 'দক্ষতা স্তর',
  ru: 'Уровень экспертизы',
  ja: '専門レベル',
  yue: '專業程度',
  vi: 'Trình độ chuyên môn',
  tr: 'Uzmanlık Seviyesi',
  wuu: '专业水平',
  mr: 'विशेषज्ञता स्तर',
  te: 'నిపుణత స్థాయి',
  ko: '전문성 수준',
  fr: "Niveau d'expertise",
  ta: 'திறன் நிலை',
  arz: 'مستوى الخبرة',
  de: 'Expertise Level',
  ur: 'ماہری کا سطح',
  jv: 'Tingkat Keahlian',
  pa: 'ਵਿਸ਼ੇਸ਼ਜਣਤਾ ਦਰਜਾ',
  it: 'Livello di esperienza',
  gu: 'વિશેષજ્ઞતા સ્તર',
  fa: 'سطح تخصص',
  bho: 'विशेषज्ञता स्तर',
  ha: 'Karin Bayani',
}

kLocaleString[
  'Provides helpful responses and insights for general inquiries.'
] = {
  zh: '为一般查询提供有用的回答和见解。',
  es: 'Proporciona respuestas útiles e ideas para consultas generales.',
  hi: 'सामान्य पूछताछ के लिए सहायक प्रतिक्रियाएं और दर्शन प्रदान करता है।',
  pt: 'Fornece respostas úteis e insights para perguntas gerais.',
  bn: 'সাধারণ জিজ্ঞাসার জন্য উপকারী প্রতিক্রিয়া এবং পরামর্শ প্রদান করে।',
  ru: 'Предоставляет полезные ответы и идеи для общих запросов.',
  ja: '一般的な問い合わせに対する役立つ回答と洞察を提供します。',
  yue: '為一般查詢提供有用嘅回應同埋見解。',
  vi: 'Cung cấp câu trả lời hữu ích và những hiểu biết cho các câu hỏi chung.',
  tr: 'Genel sorular için yararlı cevaplar ve görüşler sağlar.',
  wuu: '为常规查询提供有用个回应同埋见解。',
  mr: 'सामान्य पूछताळांसाठी सहाय्यक प्रतिसाद आणि ज्ञान पुरवतो.',
  te: 'సాధారణ ప్రశ్నలకు ఉపయోగకరమైన సమాధానాలు మరియు అవగాహనలను అందిస్తుంది.',
  ko: '일반 문의에 대한 유용한 응답과 통찰력을 제공합니다.',
  fr: 'Fournit des réponses utiles et des idées pour les demandes générales.',
  ta: 'பொது வினாவிற்கு உதவும் பதில்கள் மற்றும் புரியும் அறிவை வழங்குகின்றன.',
  arz: 'يقدم إجابات مفيدة وتحليلات للاستفسارات العامة.',
  de: 'Bietet hilfreiche Antworten und Einblicke für allgemeine Anfragen.',
  ur: 'عام سوالات کے لئے مددگار جوابات اور بصیرت فراہم کرتا ہے۔',
  jv: 'Nate ngasilake tanggapan lan wawasan sing berguna kanggo pitakon umum.',
  pa: 'ਆਮ ਪੁੱਛਤਾਲਾਂ ਲਈ ਮਦਦਗਾਰ ਜਵਾਬ ਅਤੇ ਅਧਿਐਨ ਪ੍ਰਦਾਨ ਕਰਦਾ ਹੈ।',
  it: 'Fornisce risposte utili e soluzioni per richieste generali.',
  gu: 'સામાન્ય પુછપરછ માટે ઉપયોગી જવાબો અને અંદાજો પૂરા કરે છે.',
  fa: 'پاسخ‌های مفید و بینش‌هایی را برای پرسش‌های عمومی فراهم می کند.',
  bho: 'साधारण पूछता के लिहाज से मददगार उत्तरें देत बा आ परिश्रम देत बा।',
  ha: "Ya bayyana mafi kyau na samfurin da kuma nazarin ga tambayoyi na jumla'a.",
}

kLocaleString[
  'Provides more refined responses for deeper and more personalized insights.'
] = {
  zh: '提供更精细的回复，为更深入和更个性化的洞察提供支持。',
  es: 'Proporciona respuestas más refinadas para una comprensión más profunda y personalizada.',
  hi: 'गहरी और और व्यक्तिगत अंदाज़ के लिए अधिक विस्तृत जवाब प्रदान करता है।',
  pt: 'Fornece respostas mais refinadas para insights mais profundos e personalizados.',
  bn: 'গভীর এবং ব্যক্তিগত বিষয়বস্তুর জন্য আরও উন্নয়নযোগ্য প্রতিক্রিয়া সরবরাহ করে।',
  ru: 'Предоставляет более точные ответы для более глубокого и персонализированного понимания.',
  ja: 'より深く個人に合わせた洞察を得るために、より洗練された回答を提供します。',
  yue: '為更深入和更個人化嘅洞察提供更精細嘅回應。',
  vi: 'Cung cấp các phản hồi tinh tế hơn để có cái nhìn sâu sắc và cá nhân hóa hơn.',
  tr: 'Daha derin ve kişiselleştirilmiş görüşler için daha ince yanıtlar sağlar.',
  wuu: '为更深入和更个性化嘅洞察提供更精细嘅回答。',
  mr: 'उदाहरणार्थ, अधिक उन्नत उत्तरे देण्यासाठी विशेषतः आरोग्याच्या विषयांवर तपासून घेतलेल्या डेटांचा वापर केला जातो.',
  te: 'మరిన్ని పరిశీలనల కోసం మరిన్ని సవరించబడిన సమాధానాలను అందిస్తుంది.',
  ko: '더 깊고 개인화된 인사이트를 위해 보다 정교한 응답을 제공합니다.',
  fr: 'Fournit des réponses plus affinées pour des insights plus profonds et personnalisés.',
  ta: 'ஆழமான மற்றும் தனிப்பட்ட புலனாக்கங்களுக்கு மேலும் அரைக்கப்பட்ட பதில்களை வழங்குகின்றன.',
  arz: 'يوفر ردود أفضل وأكثر دقة للتحليلات الأكثر تعمقًا وتخصيصًا.',
  de: 'Bietet präzisere Antworten für tiefere und personalisierte Erkenntnisse.',
  ur: 'عمیق ترین اور شخصی بصیرت کے لئے مزید خلاصہ دار جوابات فراہم کرتا ہے۔',
  jv: 'Nerapake tanggapan sing luwih apik kanggo wawasan sing luwih dhuwur lan dipersonalisasi.',
  pa: 'ਗੂੰਜਲ ਅਤੇ ਹੁਣਮਰੀ ਅਧਿਆਪਨ ਲਈ ਹੋਰ ਬੇਹਤਰ ਜਵਾਬ ਪ੍ਰਦਾਨ ਕਰਦਾ ਹੈ।',
  it: 'Fornisce risposte più raffinate per insights più profondi e personalizzati.',
  gu: 'ગભરેલા અને વ્યક્તિગત અંદાજ માટે વધુ પરિષ્કૃત જવાબો પૂર્ણ કરે છે.',
  fa: 'پاسخ‌های دقیق‌تری را برای بینش‌های عمیق‌تر و سفارشی‌تر فراهم می‌کند.',
  bho: 'गहरी और और व्यक्तिगत अंदाज़ के लिए अधिक विस्तृत जवाब प्रदान करता है।',
  ha: 'Yi amfani da sababbin jazakarwa domin rukunin da suka kasance a kusan da kusan.',
}

kLocaleString['1 credit per message'] = {
  zh: '每条信息1个积分',
  es: '1 crédito por mensaje',
  hi: 'प्रति संदेश 1 क्रेडिट',
  pt: '1 crédito por mensagem',
  bn: 'প্রতি বার্তার জন্য 1 ক্রেডিট',
  ru: '1 кредит за сообщение',
  ja: 'メッセージごとに1クレジット',
  yue: '每條訊息1個積分',
  vi: '1 tín chỉnh cho mỗi tin nhắn',
  tr: 'Her mesaj başına 1 kredi',
  wuu: '每条消息1个积分',
  mr: 'प्रत्येक संदेशासाठी 1 क्रेडिट',
  te: 'ఒక సందేశం క్రెడిట్ గల ఒక్కసారి',
  ko: '메시지 당 1 크레딧',
  fr: '1 crédit par message',
  ta: 'ஒரு செய்தி ஒரு கிரெடிட்',
  arz: '1 كريديت لكل رسالة',
  de: '1 Kredit pro Nachricht',
  ur: 'فی میسج 1 کریڈٹ',
  jv: '1 kredit menyang pesen',
  pa: 'ਹਰ ਸੁਨੇਹੇ ਵਿੱਚ 1 ਕ੍ਰੇਡਿਟ',
  it: '1 credito per messaggio',
  gu: 'ઓછી મેસેજ માટે 1 ક્રેડિટ',
  fa: '۱ اعتبار برای هر پیام',
  bho: 'हर संदेश के लिए 1 क्रेडिट',
  ha: '1 kudin katin lamba',
}

kLocaleString['credits per message'] = (n: number) => ({
  zh: `每条信息${n}个积分`,
  es: `${n} créditos por mensaje`,
  hi: `प्रति संदेश ${n} क्रेडिट`,
  pt: `${n} créditos por mensagem`,
  bn: `প্রতি বার্তার জন্য ${n} ক্রেডিট`,
  ru: `${n} кредита за сообщение`,
  ja: `メッセージごとに${n}クレジット`,
  yue: `每條訊息${n}個积分`,
  vi: `${n} tín chỉ mỗi tin nhắn`,
  tr: `Mesaj başına ${n} kredi`,
  wuu: `每条信息${n}个积分`,
  mr: `प्रत्येक संदेशासाठी ${n} क्रेडिट`,
  te: `సందేశం ప్రతికం ${n} క్రెడిట్లు`,
  ko: `메시지 당 ${n} 크레딧`,
  fr: `${n} crédits par message`,
  ta: `ஒவ்வொரு செய்தி முன்னாள் ${n} கிரெடிட்`,
  arz: `${n} كريديت لكل رسالة`,
  de: `${n} Credits pro Nachricht`,
  ur: `ہر پیغام فی ${n} کریڈٹ`,
  jv: `${n} kredit saben pesen`,
  pa: `ਹਰ ਸੁਨੇਹੇ ਵਿੱਚ ${n} ਕ੍ਰੈਡਿਟ`,
  it: `${n} crediti per messaggio`,
  gu: `પ્રતિ સંદેશ માટે ${n} ક્રેડિટ`,
  fa: `${n} اعتبار در هر پیام`,
  bho: `प्रति संदेश ${n} क्रेडिट`,
  ha: `${n} kudin kiran gobe`,
})

kLocaleString['Basic'] = {
  zh: '基础的',
  es: 'Básico',
  hi: 'बुनियादी',
  pt: 'Básico',
  bn: 'মৌলিক',
  ru: 'Основной',
  ja: '基本的な',
  yue: '基本',
  vi: 'Cơ bản',
  tr: 'Temel',
  wuu: '基础',
  mr: 'मूलभूत',
  te: 'బేసిక్',
  ko: '기본적인',
  fr: 'Basique',
  ta: 'அடிப்படை',
  arz: 'اساسى',
  de: 'Einfach',
  ur: 'بنیادی',
  jv: 'Dasar',
  pa: 'ਬੁਨਿਆਦੀ',
  it: 'Base',
  gu: 'બેસિક',
  fa: 'پایه',
  bho: 'बुनियादी',
  ha: 'Basa',
}

kLocaleString['Advanced'] = {
  zh: '高级',
  es: 'Avanzado',
  hi: 'उन्नत',
  pt: 'Avançado',
  bn: 'উন্নয়নশীল',
  ru: 'Продвинутый',
  ja: '高度な',
  yue: '高級',
  vi: 'Nâng cao',
  tr: 'Gelişmiş',
  wuu: '先进的',
  mr: 'उन्नत',
  te: 'ఉన్నత',
  ko: '고급',
  fr: 'Avancé',
  ta: 'மேம்பட்ட',
  arz: 'متقدم',
  de: 'Fortgeschritten',
  ur: 'اعلی درجے کا',
  jv: 'Lanjutan',
  pa: 'ਤਰੱਕੀ ਪਾਈ',
  it: 'Avanzato',
  gu: 'ઉન્નત',
  fa: 'پیشرفته',
  bho: 'उन्नत',
  ha: 'Sauki',
}

kLocaleString['Expertise level updated'] = {
  zh: '专业水平已更新',
  es: 'Nivel de experiencia actualizado',
  hi: 'विशेषज्ञता स्तर अपडेट किया गया',
  pt: 'Nível de especialização atualizado',
  bn: 'দক্ষতা স্তর আপডেট করা হয়েছে',
  ru: 'Уровень экспертизы обновлен',
  ja: '専門レベルを更新しました',
  yue: '專業水平更新咗',
  vi: 'Cập nhật trình độ chuyên môn',
  tr: 'Uzmanlık seviyesi güncellendi',
  wuu: '专业水平更新咯',
  mr: 'विशेषज्ञता स्तर अपडेट केले गेले',
  te: 'విశేషజ్ఞత స్థాయిని నవీకరించబడింది',
  ko: '전문 지식 수준이 업데이트되었습니다',
  fr: "Niveau d'expertise mis à jour",
  ta: 'துறைப்படுத்தும் நிலை புதுப்பிக்கப்பட்டது',
  arz: 'تحديث مستوى الخبرة',
  de: 'Expertise-Level aktualisiert',
  ur: 'خبرہ کا سطح اپ ڈیٹ کیا گیا',
  jv: 'Levelek keahlian ditingkatna',
  pa: 'ਵਿਸ਼ੇਸ਼ਜਣਤਾ ਦੀ ਸਤੰਬੰਧੀ ਤਾਜ਼ਾ ਜਾਣਕਾਰੀ ਦਿੱਤੀ ਗਈ ਹੈ',
  it: 'Livello di competenza aggiornato',
  gu: 'વિશેષજ્ઞતા સ્તર અપડેટ કર્યું',
  fa: 'سطح تخصص به‌روز شد',
  bho: 'विशेषज्ञता स्तर अपडेट करल गेल बा',
  ha: 'Sami na kwarewar jin dadi mai nauyi',
}

/**
 * Misc strings
 */

kLocaleString['Settings'] = {
  zh: '设置',
  es: 'Configuraciones',
  hi: 'सेटिंग्स',
  pt: 'Configurações',
  bn: 'সেটিংস',
  ru: 'Настройки',
  ja: '設定',
  yue: '設定',
  vi: 'Cài đặt',
  tr: 'Ayarlar',
  wuu: '设定',
  mr: 'सेटिंग्ज',
  te: 'సెట్టింగ్స్',
  ko: '설정',
  fr: 'Paramètres',
  ta: 'அமைப்புகள்',
  arz: 'إعدادات',
  de: 'Einstellungen',
  ur: 'ترتیبات',
  jv: 'Setelan',
  pa: 'ਸੈਟਿੰਗਾਂ',
  it: 'Impostazioni',
  gu: 'સેટિંગ્સ',
  fa: 'تنظیمات',
  bho: 'सेटिंग्स',
  ha: 'Saita',
}
kLocaleString['Send feedback'] = {
  zh: '发送反馈',
  es: 'Enviar comentarios',
  hi: 'प्रतिक्रिया भेजें',
  pt: 'Enviar feedback',
  bn: 'প্রতিক্রিয়া পাঠান',
  ru: 'Отправить отзыв',
  ja: 'フィードバックを送信する',
  yue: '發送反饋',
  vi: 'Gửi phản hồi',
  tr: 'Geribildirim gönder',
  wuu: '发反馈',
  mr: 'प्रतिसाद पाठवा',
  te: 'అభిప్రాయాన్ని పంపండి',
  ko: '피드백 보내기',
  fr: 'Envoyer des commentaires',
  ta: 'கருத்துக்களை அனுப்புங்கள்',
  arz: 'ارسل رأيك',
  de: 'Feedback senden',
  ur: 'آراء بھیجیں',
  jv: 'Kirim feedback',
  pa: 'ਫੀਡਬੈਕ ਭੇਜੋ',
  it: 'Invia feedback',
  gu: 'પ્રતિસાદ મોકલો',
  fa: 'ارسال بازخورد',
  bho: 'प्रतिक्रिया भेजें',
  ha: 'Send feedback',
}

kLocaleString['Show suggestions'] = {
  zh: '显示选项',
  es: 'Mostrar opciones',
  hi: 'विकल्प दिखाएं',
  pt: 'Mostrar opções',
  bn: 'অপশনগুলি দেখান',
  ru: 'Показать варианты',
  ja: 'オプションを表示',
  yue: '顯示選項',
  vi: 'Hiển thị tùy chọn',
  tr: 'Seçenekleri göster',
  wuu: '显示选项',
  mr: 'पर्याय दाखवा',
  te: 'ఎంపికలు చూపించు',
  ko: '옵션 표시',
  fr: 'Afficher les options',
  ta: 'விருப்பங்களை கா',
  arz: 'عرض الخيارات',
  de: 'Optionen anzeigen',
  ur: 'آپشن دکھائیں',
  jv: 'Tampilake pilihan',
  pa: 'ਵਿਕਲਪ ਦਿਖਾਓ',
  it: 'Mostra opzioni',
  gu: 'વિકલ્પો બતાવો',
  fa: 'نمایش گزینه ها',
  bho: 'विकल्प दिखावे',
  ha: 'Nuna zaɓuɓɓuka',
}
