import { Flex, FlexProps, Text } from '@chakra-ui/react'

const ErrorBox = ({ children, ...props }: FlexProps) => (
  <Flex
    bg='red.100'
    w='fit-content'
    p={1}
    px={2}
    rounded='md'
    whiteSpace='pre'
    {...props}
  >
    <Text color='red.900' fontSize='md'>
      {children}
    </Text>
  </Flex>
)

export default ErrorBox
