import NextProgressBar from 'nextjs-progressbar'
import theme from 'styles/theme'

const AppProgressBar = () => (
  <NextProgressBar
    color={theme.colors.brand[500]}
    startPosition={0.2}
    stopDelayMs={200}
    height={2}
    options={{ showSpinner: false }}
  />
)

export default AppProgressBar
