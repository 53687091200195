import { Flex, Text } from '@chakra-ui/react'
import useLocaleString from 'hooks/useLocalString'
import { useUserProvider } from 'providers/UserProvider'
import { isDev } from 'util/env'

type Props = {
  shortHand?: boolean
}

const CreditBadge = ({ shortHand }: Props) => {
  const { user, spendCredit } = useUserProvider()

  // Localized strings
  const creditsString = useLocaleString('credits')
  const creditString = useLocaleString('credit')
  const suffix = !shortHand && user?.locale === 'en' ? ' remaining' : ''

  /**
   * Debug only to spend credits
   */
  const handleClick = () => {
    if (isDev()) {
      spendCredit(1)
    }
  }

  return (
    <Flex
      border='1px solid'
      borderColor='blue.300'
      px={2}
      h='28px'
      rounded='md'
      align='center'
      onClick={handleClick}
      mr={1}
      w='fit-content'
    >
      <Text fontWeight={500} fontSize='14px' color='blue.600'>
        {user?.subscription?.credits}{' '}
        {user?.subscription?.credits !== 1 ? creditsString : creditString}
        {suffix}
      </Text>
    </Flex>
  )
}

export default CreditBadge
